import React from 'react';

const SearchContext = React.createContext();

export default SearchContext;

export const SearchProvider = ({ children }) => {
    const [searchParams, setSearchParams] = React.useState({});
  
    return (
      <SearchContext.Provider value={{ searchParams, setSearchParams }}>
        {children}
      </SearchContext.Provider>
    );
  };
  