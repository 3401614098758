import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient'; 
import './App.css';
import { Helmet } from 'react-helmet';
import CreateTravelModal from './CreateTravelModal';
import ConfirmationModal from './ConfirmDestinationRemovalModal';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/'
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com';

function TravelPage() {
    const [loading, setLoading] = useState(true);
    const [destinations, setDestinations] = useState([]); 
    const { id } = useParams();
    const [travelName, setTravelName] = useState('');
    const [travelDescription, setTravelDescription] = useState('');
    const [travelToken, setTravelToken] = useState('');
    const [user, setUser] = useState(null); // Ajoutez un état pour suivre l'utilisateur connecté

    const navigate = useNavigate(); // Utilisez useNavigate pour la redirection
    const location = useLocation();

    const getTokenFromLocation = () => {
        const query = new URLSearchParams(location.search);
        return query.get('token'); // Assurez-vous que 'token' est le nom correct du paramètre
    };
    
    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setUser(session?.user || null); // Mettez à jour l'état de l'utilisateur avec l'utilisateur connecté ou null
      });
    }, []);

    useEffect(() => {
      const token = getTokenFromLocation();
      const checkAccess = async () => {
          if (token) {
              // Logique pour vérifier si le token est valide
              // Exemple : 
              const { data, error } = await supabase
                  .from('user_travels')
                  .select('*')
                  .eq('share_token', token) // Assurez-vous que 'token' est le bon nom de champ
                  .single();

              if (error || !data) {
                  console.error('Accès refusé', error);
                  navigate('/erreur'); // Rediriger vers une page d'erreur ou de connexion
              } else {
                  // Le token est valide, continuez avec la logique spécifique de la page
                  // Pas besoin de rediriger
              }
          } else {
              const { data: user, error } = await supabase.auth.getUser();
              if (error || !user) {
                  navigate('/connexion'); // Rediriger si aucun utilisateur n'est connecté
              }
              // Si l'utilisateur est connecté, continuez sans vérifier le token
          }
      };

      checkAccess();
  }, [navigate, location.search]); 
    
    useEffect(() => {
      // Assurez-vous d'avoir récupéré l'identifiant du voyage à partir des params URL ou autre.
      const fetchTravelDetails = async () => {
          const { data, error } = await supabase
              .from('user_travels')
              .select('*')
              .eq('id', id) // 'id' est le user_travel_id
              .single() // Utilisez .single() pour obtenir un seul résultat
  
          if (error) {
              console.error('Erreur lors de la récupération du nom du voyage:', error);
              return;
          }
  
          setTravelName(data.name);
          setTravelDescription(data.description);
          setTravelToken(data.share_token);
      };
  

      const fetchDestinations = async () => {
          setLoading(true);
  
          const { data: destinationsData, error: destinationsError } = await supabase
              .from('travel_destinations')
              .select('*')
              .eq('user_travel_id', id);
  
              if (destinationsError) {
              console.error('Erreur lors de la récupération des destinations:', destinationsError);
              setLoading(false);
              return;
          }

         

          // Fonction pour récupérer les détails de la ville basée sur city_id
          const fetchCityDetails = async (cityId) => {
              try {
                  const response = await fetch(`${API_URL}api/city/${cityId}`);
                  if (!response.ok) throw new Error('Erreur de récupération des détails de la ville');
                  const cityData = await response.json();
                  return cityData;
              } catch (error) {
                  console.error('Erreur lors de la récupération des détails de la ville:', error);
                  return null;
              }
          };
  
          // Itérer sur chaque destination pour enrichir avec les détails de la ville
          const enrichedDestinations = await Promise.all(destinationsData.map(async (destination) => {
              const cityId = (destination.destination_id);
              const cityDetails = await fetchCityDetails(destination.destination_id);
              return { ...destination, cityDetails };
          }));
  
          setDestinations(enrichedDestinations);
          setLoading(false);
      };
      fetchTravelDetails();
      fetchDestinations();

  }, [id]);

  const shareTravel = async () => {
    const token = travelToken;    // Construire le lien avec le token
    const shareUrl = `${window.location.origin}${window.location.pathname}?token=${travelToken}`;

    try {
        // Utiliser l'API de presse-papier pour copier le lien
        await navigator.clipboard.writeText(shareUrl);
        alert('Lien copié dans le presse-papier !'); // Affichez un message de confirmation (optionnel)
    } catch (error) {
        console.error('Erreur lors de la copie du lien :', error);
        alert('Erreur lors de la copie du lien.'); // Affichez un message d'erreur (optionnel)
    }
};


const [isModalOpen, setIsModalOpen] = useState(false);
const [destinationIdToDelete, setDestinationIdToDelete] = useState(null);

// Lorsque l'utilisateur souhaite retirer une destination
const requestRemoveDestination = (destinationId) => {
  setDestinationIdToDelete(destinationId);
  setIsModalOpen(true);
};

// Gérer la confirmation de suppression
const removeDestination = async () => {
  try {
    // Utilisez destinationIdToDelete pour supprimer la destination
    const { error } = await supabase
      .from('travel_destinations')
      .delete()
      .eq('id', destinationIdToDelete); // Correction pour utiliser .match() avec l'ID stocké

    if (error) throw error;

    // Actualisez l'état des destinations pour refléter la suppression
    setDestinations(currentDestinations => currentDestinations.filter(destination => destination.id !== destinationIdToDelete));

    setIsModalOpen(false); // Fermer la modale après confirmation
  } catch (error) {
    console.error('Erreur lors de la suppression de la destination:', error);
    setIsModalOpen(false); // Vous pouvez choisir de fermer la modale ou non en cas d'erreur
  }
};






    return (
        <div className="App">  
           <Helmet>
            <title>Mon voyage sur Vaiago</title>
          </Helmet>
            <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
            <link rel="canonical" href="https://www.vaiago.com" />
          
    
          <div className="page">
          <h1>{travelName}</h1>
          <p>{travelDescription}</p>
          {loading ? (
            <p>Chargement...</p>
          ) : destinations.length > 0 ? ( 
            <div>
              <div> {user && (
                <button onClick={shareTravel} className="share-btn">
                  <i className="fa-solid fa-share-nodes"></i> Partager ce voyage
                </button>
            )}</div>
            <div className="destinations">
              {destinations.map((destination) => (
                  <div className="card-destination" key={destination.cityDetails?.id}>
                    <div className="picture">
                    <img src={`${FILES_URL}/img/destinations/${destination.cityDetails?.slug}-cover.webp`} alt={destination.cityDetails?.name} />
                    </div>
                    <div className="content">
                      <div className="destination-header">
                      <a key={destination.destination_id} className="destination-link" href={`/destination/${destination.cityDetails?.slug}`}><h2>{destination.cityDetails?.name}</h2> </a>
                      {user && (<div> <button onClick={() => requestRemoveDestination(destination.id)} className="delete-btn"><i className="fa fa-trash"></i></button></div>)}
                      </div>
                      <div>
                        <p>{destination.cityDetails?.short_description}</p>
                      </div>
                    </div>
                  </div>
              ))}
              <br></br> <br></br>
            {user && (<div className="fixed-bottom-mobile"><a href="/"><button>Chercher une destination</button></a><br></br></div>)}

            </div>
            </div>
          ) : (
            <div>
            {user && (<div className="empty-state">
              <p>Aucune destination ajoutée. <br></br>Ajoutez vos premières destinations.</p> 
              <img src="https://i.imgur.com/sm3kwfm.png" className="empty"></img> 
               <a href="/"><button>Chercher une destination</button></a><br></br></div>)}
</div>
            )}
            
          </div>
          <div><ConfirmationModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} onConfirm={removeDestination}/></div>
        </div>
        
      );
    }

export default TravelPage;
