import React, { useContext, useEffect, useState } from 'react';
import Header from './Header'; // Adjust the path if necessary
import Footer from './Footer'; // Adjust the path if necessary


function Terms() {
  return (
    <div className="terms">    
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com/mentions-legales"/>

        <div className="container">
            <div className="block-page">
                <h1>Mentions légales</h1>
                <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Vaiago l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
                <strong>Edition du site </strong>
                <p>Le présent site, accessible à l’URL www.vaiago.com (le « Site »), est édité par Adèle Sold et Helene Gloux.</p>   
                <strong>Hébergement du site </strong>
                <p>Le Site est hébergé par la société Digital Ocean, situé New York, 101 6th Ave, United States.</p>
                <strong>Données personnelles</strong>
                <p>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
        </p>
            </div>
        </div>
        
    </div>

  );
}

export default Terms;
