import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import './App.css';
import { Helmet } from 'react-helmet';
import CreateTravelModal from './CreateTravelModal';
import ConfirmationModal from './ConfirmTravelRemovalModal'; // Assurez-vous d'importer votre composant de modale de confirmation

function Travels() {
  const [loading, setLoading] = useState(true);
  const [travels, setTravels] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [travelIdToDelete, setTravelIdToDelete] = useState(null);

  const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/'
  const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com'

  useEffect(() => {
    const fetchCityDetails = async (cityId) => {
      try {
        const response = await fetch(`${API_URL}api/city/${cityId}`);
        if (!response.ok) throw new Error('Erreur de récupération des détails de la ville');
        const cityData = await response.json();
        return cityData;
      } catch (error) {
        console.error('Erreur lors de la récupération des détails de la ville:', error);
        return null;
      }
    };
  
    const fetchUserAndTravels = async () => {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        console.error("Erreur lors de la récupération de l'utilisateur ou utilisateur non connecté:", userError);
        setLoading(false);
        return;
      }
    
      const { data: travelsData, error: travelsError } = await supabase
        .from('user_travels')
        .select('*')
        .eq('user_id', user.id);
    
      if (travelsError) {
        console.error('Erreur lors de la récupération des voyages:', travelsError);
        setLoading(false);
        return;
      }
    
      const travelsWithDetails = await Promise.all(travelsData.map(async (travel) => {
        const { data: destinationsData, error: destinationsError } = await supabase
          .from('travel_destinations')
          .select('*')
          .eq('user_travel_id', travel.id)
          .order('created_at', { ascending: true })
          .limit(1);
    
        if (destinationsError || destinationsData.length === 0) {
          // Gestion des voyages sans destinations
          return { ...travel, noDestination: true }; // Marqueur pour indiquer qu'aucune destination n'est associée
        }
    
        const destination = destinationsData[0];
        const cityDetails = await fetchCityDetails(destination.destination_id);
    
        return { ...travel, destination: { ...destination, cityDetails } };
      }));
    
      setTravels(travelsWithDetails);
      setLoading(false);
    };
    
  
    fetchUserAndTravels();
  }, []);
  

  const requestRemoveTravel = (travelId) => {
    setTravelIdToDelete(travelId);
    setIsConfirmModalOpen(true);
  };

  const removeTravel = async () => {
    try {
      const { error } = await supabase
        .from('user_travels')
        .delete()
        .eq('id', travelIdToDelete);

      if (error) throw error;

      setTravels(travels.filter(travel => travel.id !== travelIdToDelete));
      setIsConfirmModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la suppression du voyage:', error);
    }
  };

  return (
    <div className="App">  
      <Helmet>
        <title>Mes idées voyages sur Vaiago</title>
      </Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
      <link rel="canonical" href="https://www.vaiago.com" />
    
      <div className="page">
        <h1>Mes voyages</h1>
        {loading ? <p>Chargement...</p> : (
          travels.length > 0 ? (
           <div> <div className="destinations">
              {travels.map((travel) => (
                <div className="card-destination" key={travel.id}>
                  <div className="picture">
                  {travel.noDestination ? (<a key={travel.id} className="destination-link" href={`/voyages/${travel.id}`}>
                  <img src='https://i.imgur.com/R1RwK1I.png' alt={travel.name} /></a>):(<a key={travel.id} className="destination-link" href={`/voyages/${travel.id}`}><img src={`${FILES_URL}/img/destinations/${travel.destination.cityDetails?.slug}-cover.webp`} alt={travel.name} /></a>)}
                  </div>
                  <div className="content">
                     <div className="destination-header">
                        <a key={travel.id} className="destination-link" href={`/voyages/${travel.id}`}><h2>{travel.name}</h2> </a>
                        <button onClick={() => requestRemoveTravel(travel.id)} className="delete-btn"><i className="fa fa-trash"></i></button>
                      </div>  
                      <div><p>{travel.description}</p></div>                
                  </div>
                </div>
              ))}
            </div>
            <br></br>            <br></br>

            <div  className="fixed-bottom-mobile"><button onClick={() => setIsCreateModalOpen(true)}>Ajouter un voyage</button></div>
          </div>
       ) : <div className="empty-state">
            <p>Aucun voyage existant.</p> 
            <img src={`${FILES_URL}/img/app/empty.webp`} className="empty" />
            <button onClick={() => setIsCreateModalOpen(true)}>Ajouter un voyage</button>
            </div>
        )}
        <br></br> <br></br>
        <CreateTravelModal isOpen={isCreateModalOpen} onRequestClose={() => setIsCreateModalOpen(false)} />
        <ConfirmationModal
          isOpen={isConfirmModalOpen}
          onRequestClose={() => setIsConfirmModalOpen(false)}
          onConfirm={removeTravel}
          message="Êtes-vous sûr de vouloir supprimer ce voyage ?"
        />
      </div>
    </div>
  );
}

export default Travels;
