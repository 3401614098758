import React, { useContext, useEffect, useState } from 'react';
import Header from './Header'; // Adjust the path if necessary
import Footer from './Footer'; // Adjust the path if necessary


function Confidentiality() {
  return (
    <div className="terms">    
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com/mentions-legales"/>

        <div className="container">
            <div className="block-page">
                <h1>Politique de confidentialité</h1>
                <p>Cette politique a pour objet de vous informer sur la manière dont nous collections et traitons vos données personnelles, avec nos prestataires, ainsi que des droits dont vous disposez à l’égard de ces données. Cette politique s’applique à l’ensemble des services proposés par Vaiago, utilisables à travers les différentes interfaces mises à disposition en complément des conditions générales d’utilisation applicables à l’ensemble de ces services.</p>
                <p>Hélène Gloux, en tant qu'éditrice du site <a href="https://www.vaiago.com">vaiago.com</a>, a la qualité de responsable du traitement au regard de la législation sur la protection des données personnelles.</p>

                <h2>1. Informations collectées</h2>
                <p>Vaiago ne traite aucune Données sensibles au sens de la législation sur la protection des données personnelles.</p>

                <h3>1.1. Données nécessaires à la création d’un compte utilisateur</h3>
                <p>Nous collectons, en direct ou à travers des partenaires pour lesquels les Personnes Concernées ont donné leur consentement à la collecte et au partage de leurs Données, des informations destinées à permettre de créer un compte utilisateur le cas échéant. Il s’agit des Données d’identification et de contact suivantes :</p>
                <ul>
                    <li>Nom (facultatif)</li>
                    <li>Prénom (facultatif)</li>
                    <li>Date de naissance (facultative)</li>
                    <li>Ville (facultatif)</li>
                    <li>Adresse e-mail</li>
                </ul>

                <h3>1.2. Données issues de la navigation sur le site web Vaiago</h3>
                <p>Nous collectons des informations liées à la navigation des utilisateurs sur le site internet Vaiago, incluant l’historique de connexion, les pages visitées, les identifiants de connexion comme l’adresse IP, etc. Nous utilisons des cookies pour effectuer certaines de ces opérations de traçage.</p>

                <h2>2. Finalités du traitement des Données collectées et bases juridiques</h2>
                <h3>2.1. Traitements nécessaires au fonctionnement du site Vaiago</h3>
                <p>Nous utilisons les informations à notre disposition (en fonction des choix que vous effectuez) de la façon décrite ci-dessous, afin de fournir et d’assurer le fonctionnement du site Vaiago et les services associés décrits dans les Conditions d’utilisation de Vaiago.</p>

                <p>Voici de quelle façon :</p>
                <ul>
                <li>Utilisation des Données personnelles d'identification (nom, prénom, date de naissance et ville) dans l'objectif de la création et de l'utilisation d'un compte utilisateur Vaiago. </li>
                <li>Utilisation des Données de contact (e-mail) à des fins de communication, automatisées (selon les actions de l'utilisateur sur le site) ou non, entre les équipes de Vaiago et les utilisateurs.</li>
                </ul>
                

                <h3>2.2. Traitements nécessaires aux fins des intérêts légitimes de Vaiago</h3>
                <p>Vaiago est amenée à traiter certaines Données dans un but légitime, comme la gestion de la relation utilisateur, ainsi que l’amélioration de ses produits et services. Dans ce cadre, Vaiago traite notamment des Données relatives à :</p>
                <ul>
                <li>la navigation sur le site internet</li>
                <li>la gestion du support avec les visiteurs et les utilisateurs</li>
                <li>Etc.</li>
                </ul>

                <h2>3. Durée de conservation des Données</h2>
                <p>La durée de conservation des Données dépend de la finalité poursuivie, mais elles sont généralement conservées pendant la durée de la relation avec les utilisateurs, puis sont archivées le temps nécessaire pour permettre à Vaiago de répondre à ses obligations légales.</p>

                <table class="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col">Finalités d’utilisation des Données</th>
                    <th scope="col">Durée de conservation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Données d'identification (nom, prénom, date de naissance et ville)</td>
                    <td>Nous conservons ces Données tant que le compte est actif.</td>
                    </tr>
                    <tr>
                    <td>Données de contact (e-mail)</td>
                    <td>Nous conservons ces Données tant que le compte est actif.</td>
                    </tr>
                    <tr>
                    <td>Gestion des voyages</td>
                    <td>Nous conservons ces Données tant que le compte est actif.</td>
                    </tr>
                    <tr>
                    <td>Adresses IP et cookies</td>
                    <td>Nous conservons ces Données tant que le compte est actif.</td>
                    </tr>
                </tbody>
                </table>

                <h2>4. Cookies</h2>
                <p>Vous pouvez consulter notre politique de gestion des cookies sur le lien suivant : <a href="https://www.vaiago.com/cookies">https://www.vaiago.com/cookies</a></p>

                <h2>5. Sécurité des Données</h2>
                <p>Les Données traitées par Vaiago sont protégées par des mesures de sécurité adaptées en fonction du caractère plus ou moins sensible des Données traitées, même si Vaiago ne traite aucune « données à caractère sensible » au sens de la réglementation française et européenne.</p>
                <p>Les mesures techniques et organisationnelle suivantes protègent l’ensemble des Données traitées par Vaiago et empêchent qu’elles puissent être déformées, endommagées ou que des tiers non autorisés y aient accès : protection des formulaires en ligne (sur le site internet), utilisation de méthodes de chiffrement des informations transmises, réseaux protégés par pare-feu, accès restreints par habilitation, infrastructure de backup, utilisation de mots de passe et outil de reporting, protection physique des locaux etc.</p>
                <p>Les utilisateurs ont accès à leur espace personnel grâce à un mot de passe dont ils sont seuls responsables. Vaiago décline toute responsabilité en cas de partage de ce mot de passe avec un quelconque tiers.</p>


                <h2>6. Partage des Données</h2>
                <h3>6.1. Avec qui partageons-nous vos Données ?</h3>
                <p>Certaines informations sont transmises à nos prestataires et sous-traitants pour permettre l’utilisation des services proposés par Vaiago dans la limite nécessaire à leur intervention. Les prestataires ou sous-traitants sont principalement :</p>
                <ul>
                <li>Les prestataires d’hébergement de Données et autres prestataires de services informatiques</li>
                <li>Les partenaires d’assistance client</li>
                </ul>
                <p>Des Données peuvent également être partagées dans des cas non prévus ci-dessus avec le consentement des Personnes Concernées ou parce que Vaiago y est soumis par la législation applicable. Vaiago prend toutes les mesures nécessaires pour que les Données soient traitées en toute sécurité et conformément à la présente politique de confidentialité quand elles sont communiquées à des tiers.</p>

                <h3>6.2. Transfert de Données en dehors de l’Union Européenne</h3>
                <p>Nous traitons vos Données au sein de l’Union Européenne et conservons ces dernières au sein de ce même espace. Néanmoins, en cas de transfert international en dehors de l’Union Européenne, il convient de distinguer :</p>
                <ul>
                <li>Les pays ayant fait l’objet d’une décision d’adéquation par la Commission Européenne : dans ce cas, les Données sont transférées vers un pays assurant un niveau de protection jugé comme suffisant et adéquat aux dispositions de la règlementation européenne sur la protection des données </li>
                <li>Les pays dont le niveau de protection des données n’a pas été reconnu comme adéquat : dans ce cas, pour garantir un transfert en conformité avec la réglementation européenne, et notamment assurer la sécurité des Données, Vaiago fondera son transfert sur des mécanismes de protection adaptés à chaque prestataire ou sous-traitant, tels que la conclusion de clauses contractuelles types approuvées par la Commission Européenne, l’application de règles d’entreprises contraignantes ou en vertu d’un mécanisme de certification approuvé</li>
                </ul>

                <h2>7. Droits des personnes concernées</h2>
                <p>Afin de permettre à la Personne Concernée de pouvoir accéder à ses Données, d’en demander la rectification ou d’exercer tout autre droit sur ses Données, Vaiago doit vérifier l’identité de celle-ci et peut, à ce titre, être amenée à lui demander des informations supplémentaires qui ne seront pas conservées.</p>

                <h3>7.1. Accès</h3>
                <p>Les Personnes Concernées ont un droit d’accès et de communication de leurs Données. Certaines Données sont directement accessibles en ligne au sein des espaces personnels des utilisateurs, telles que les Données d’identification et de contact, l’historique de consommation, etc.</p>

                <h3>7.2. Rectification</h3>
                <p>Les Personnes Concernées peuvent demander la rectification de leurs Données qui seraient erronées ou incomplètes. Les Données disponibles au sein des espaces personnels  des utilisateurs peuvent être accessibles et modifiables directement par les Personnes Concernées. La modification peut en tout état de cause toujours être demandée par l'utilisateur au support de Vaiago.</p>

                <h3>7.3. Autres droits sur les Données</h3>
                <p>Les Personnes Concernées peuvent également :</p>
                <ul>
                <li>Demander l’effacement, la limitation ou s’opposer au traitement de leurs Données sous réserve de motifs légitimes</li>
                <li>Retirer leur consentement quand le traitement repose sur cette base</li>
                <li>Demander la portabilité des Données</li>
                <li>Faire part à Vaiago de leurs directives sur leurs Données après leur décès</li>
                <li>Introduire une réclamation auprès d’une autorité de contrôle compétente</li>
                </ul>

                <h2>8. Contacts</h2>
                <p>Pour toute question relative à la présente politique de confidentialité ou, de manière générale, sur la protection de leurs Données, les Personnes Concernées peuvent contacter le Délégué à la Protection des Données à l’adresse suivante: <a href='mailto:hello@vaiago.com'>hello@vaiago.com</a>.</p>
                <p>De même, pour exercer leurs droits, les Personnes Concernées peuvent contacter le support de Vaiago par tous moyens : chat, e-mail, courrier postal.</p>

            </div>
        </div>    
    </div>
    );
}

export default Confidentiality;
