import React, { useContext, useEffect, useState } from 'react';
import Header from './Header'; // Adjust the path if necessary
import Footer from './Footer'; // Adjust the path if necessary


function GeneralConditions() {
  return (
    <div className="terms">    
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com/mentions-legales"/>

        <div className="container">
            <div className="block-page">
                <h1>Conditions générales d'utilisation</h1>
                <p>Ce contrat est conclu entre :</p>
                <ul>
                <li>Le gérant du site internet <a href="https://www.vaiago.com">Vaiago.com</a>, ci-après désigné «&nbsp;l’Éditeur&nbsp;».</li>
                <li>Toute personne physique ou morale souhaitant accéder au site et à ses services, ci-après appelé «&nbsp;l’Utilisateur&nbsp;».
                </li>
                </ul>
                <p>Les conditions générales d'utilisation doivent être lues et acceptées par tout Utilisateur lors de son inscription.
                </p>
                <p>L’Éditeur se réserve le droit de modifier et de mettre à jour les conditions générales d’utilisation du site à tout moment. Chaque nouvelle version des conditions générales d’utilisation entre en vigueur à compter de la date de sa mise en ligne sur le site et doivent être de nouveau acceptées par l’Utilisateur pour continuer à utiliser les services.</p>

                <h2>Article 1 : Objet du site</h2>
                <p>Le site a pour objet de fournir à l’Utilisateur un système qui lui permet d'organiser ses futurs voyages, en mettant à disposition des idées de destinations correspondantes aux critères de l'Utilisateur. </p>
                <p>Le site s’adresse à tout type de public.</p>

                <h2>Article 2 : Accès au site</h2>
                <p>Le site est accessible à l’adresse URL <a href="https://www.vaiago.com">www.vaiago.com</a>, 24h/24, 7 jours/7, sauf cas de force majeure, pannes informatiques, opérations de maintenance, de mise à jour ou de problèmes liés aux réseaux de télécommunication. L’accès, les informations et les services proposés sur le site sont gratuits.</p>

                <h2>Article 3 : Accès aux services</h2>
                <p>Tout Utilisateur ayant accès a internet peut accéder gratuitement et depuis n’importe où au site. Les frais supportés par l’Utilisateur pour y accéder (connexion internet, matériel informatique, etc.) ne sont pas à la charge de l’Éditeur.</p>
                <p>Les services suivants ne sont accessible pour l’Utilisateur que s’il est membre du site, c’est à dire après qu’il se soit inscrit et qu’il ait accepté les conditions générales d’utilisation :</p>
                <ul>
                <li>Créer son profil membre</li>
                <li>Créer un voyage</li>
                <li>Ajouter des destinations à un voyage futur</li>
                <li>Partager son futur à des tiers</li>
                </ul>

                <h2>Article 4 : Responsabilité de l’Utilisateur</h2>
                <p>L’identifiant et le mot de passe nécessaires pour accéder aux services sont personnels et strictement confidentiels. A ce titre, l’Utilisateur doit s’en réserver l’usage exclusif.</p>
                <p>Toute action sur le site internet à l’aide des identifiants et/ou mot de passe de l’Utilisateur est considérée avoir été opérée par l’Utilisateur. Il est donc seul responsable des conséquences qui pourraient résulter de leur communication à des tiers.</p>
                <p>L’Utilisateur s’engage à informer immédiatement l’Éditeur de toute utilisation non autorisée de son identifiant et/ou de son mot de passe, et plus généralement de toute atteinte à la sécurité du site dont il aurait connaissance.</p>
                <p>L’Utilisateur s’engage à tenir des propos respectueux des autres, de la loi et des bonnes moeurs. En conséquence, l’Utilisateur accepte que ces publications soient modérées ou refusées par l’Éditeur, sans obligation de justification.</p>
                <p>L’Utilisateur est seul responsable des dommages causés aux tiers et des conséquences des réclamations ou actions qui pourraient en découler. L’Utilisateur renonce également à exercer tout recours contre l’Éditeur dans le cas de poursuites diligentées par un tiers à son encontre du fait de l’utilisation et/ou de l’exploitation illicite du site.
                </p>
                <p>L’Utilisateur s’engage à utiliser le site conformément aux présentes conditions générales d’utilisation et plus généralement dans le respect de la législation en vigueur, l’ordre public et les bonnes mœurs.</p>

                <h2>Article 5 : Responsabilité de l’Éditeur</h2>
                <p>L’Éditeur s'engage à mettre en œuvre tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données. Toutefois, il n’apporte pas une garantie de sécurité totale.</p>
                <p>L’Éditeur décline toute responsabilité :</p>
                <ul>
                <li>En cas d’interruption du site notamment pour des opérations de maintenance techniques ou d’actualisation des informations publiées.</li>
                <li>En cas d’impossibilité d’accès aux espaces et services en ligne, notamment en raison de problèmes techniques et ce quelles qu’en soient l’origine et la provenance.</li>
                <li>En cas de dommages directs ou indirects qui vous seraient causés, quelle qu’en soit la nature, résultant du contenu, de l’accès ou de l’utilisation du site internet.</li>
                <li>En cas d’utilisation anormale ou d’une exploitation illicite du site.</li>
                </ul>
                <p>L’Éditeur se réserve la possibilité de saisir toutes voies de droit à l’encontre de toute personne qui n’aurait pas respecté les dispositions relatives aux Articles 323-1 à 323-8 du Code pénal ou tout Article s’y substituant, et notamment qui accèderait frauduleusement à tout ou partie d’un système de traitement automatisé de données des services du site.</p>

                <h2>Article 6 : Propriété intellectuelle</h2>
                <p>L’ensemble des éléments constituant le site (textes, graphismes, logiciels, photographies, images, sons, vidéos, plans, logos, marques, bases de données, etc…) ainsi que le site lui-même, relèvent des législations françaises et internationales sur la propriété intellectuelle.</p>
                <p>Ils sont la propriété exclusive de l’Éditeur, hormis les éléments réalisés par des intervenants extérieurs n’ayant pas cédé leurs droits de propriété intellectuelle.</p>
                <p>En conséquence, toute personne qui consulte le site et utilise ses services, s’engage notamment :</p>
                <ul>
                <li>À ne pas intégrer tout ou partie du contenu du site dans un site tiers, que ce soit à des fins commerciales ou non.</li>
                <li>À ne pas reproduire les informations figurant sur le site sur des supports de toute nature.</li>
                </ul>
                <p>Sous réserve notamment des articles L. 122-5, L. 211-3 et L. 342-3 du Code de la propriété intellectuelle, toute utilisation non expressément autorisée par l’Éditeur entraîne une violation des droits de propriété intellectuelle et constitue une contrefaçon.</p>
                <p>Elle peut aussi entraîner une violation du droit à l’image, du droit des personnes ou de tous autres droits et
                réglementations en vigueur. Elle peut donc engager la responsabilité civile et/ou pénale de son auteur.</p>
                <p>L’Éditeur se réserve la possibilité de saisir toutes voies de droit à l’encontre des personnes qui n’auraient pas respecté les interdictions contenues dans le présent article.</p>

                <h2>Article 7 : Données personnelles</h2>
                <p>L’Éditeur accorde une grande importance à la protection des données à caractère personnel. Il met en œuvre des mesures techniques pour assurer la protection effective des données de l’Utilisateur en conformité avec la règlementation applicable.</p>
                <p>L’Utilisateur doit obligatoirement fournir des informations personnelles pour procéder à son inscription sur le site.
                </p>
                <p>L’adresse électronique (e-mail) de l’Utilisateur pourra notamment être utilisée par le site pour la communication d’informations diverses et la gestion du compte.</p>
                <p>Conformément à la règlementation relative à la protection des données (RGPD et Loi Informatique et Libertés), l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit par mail à <a href="mailto:hello@vaiago.com">hello@vaiago.com</a></p>

                <h2>Article 8 : Liens hypertextes</h2>
                <p>Le site comporte des liens hypertextes vers d’autres sites internet, édités et/ou gérés par des tiers.</p>
                <p>Les domaines vers lesquels mènent les liens hypertextes présents sur le site n’engagent pas la responsabilité de l’Éditeur, qui n’a pas de contrôle sur ces liens. Il ne saurait être tenue responsable de la qualité permanente, du contenu, de la modification ou de la suppression de ces sites.</p>

                <h2>Article 9 : Durée du contrat</h2>
                <p>La durée du présent contrat est indéterminée. Le contrat produit ses effets à l'égard de l'Utilisateur à compter du moment ou il a accepté les conditions générales d’utilisation.</p>
            </div>
        </div>
        
    </div>

  );
}

export default GeneralConditions;
