import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from './supabaseClient'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom'; // Importez useNavigate pour la redirection
import BurgerMenu from './BurgerMenu';

function Header() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault(); // Empêche le comportement par défaut du lien
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Gestion après la déconnexion réussie, par exemple, rediriger vers la page d'accueil
      navigate('/');
    } else {
      // Gérer l'erreur de déconnexion si nécessaire
      console.error('Erreur de déconnexion:', error.message);
    }
  };
  return (
    <header className="header">
    <a href="/"><div className="logo">
        <img src="https://vaiago.fra1.cdn.digitaloceanspaces.com/img/app/logo.png" alt="Logo" />
      </div></a>

      { currentUser ? (
      <div className="menu">
        <a href="/pays">Toutes les destinations</a>
        <a href="/voyages">Mes voyages</a>
        <a href="/mon-compte">Mon compte</a>
        <a href="/" onClick={handleLogout}>Déconnexion</a>
      </div>          
      ) : (
      <div className="menu">
        <a href="/pays">Toutes les destinations</a>
        <a href="/inscription">S'inscrire</a>
        <a href="/connexion">Se connecter</a>
      </div>
      )}
      <BurgerMenu  currentUser={currentUser} onLogout={handleLogout}/>

  </header>
  );
}

export default Header;