import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Assurez-vous d'appeler cette fonction quelque part au niveau racine de votre application
Modal.setAppElement('#root');

function ConfirmationModal({ isOpen, onRequestClose, onConfirm }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>  
    <div className="form-modal">
      <div className="close  hidden-desktop"><button className="close-btn" onClick={onRequestClose}><i className="fa-solid fa-xmark"></i></button></div>
      <div>Êtes-vous sûr de vouloir supprimer ce voyage ?</div>
      <button onClick={onConfirm} className="">Oui</button>
      <button onClick={onRequestClose} className="secondary">Non</button>
    </div>
    </Modal>
  );
}

export default ConfirmationModal;
