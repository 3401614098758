import React from 'react';
import sanitizeHtml from 'sanitize-html';

// Fonction pour nettoyer le contenu HTML
const cleanHTML = (htmlContent) => {
  return sanitizeHtml(htmlContent, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'span']),
    allowedAttributes: {
      '*': ['id', 'style', 'src']
    }
  });
};
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com'

// Fonction pour créer un objet markup sécurisé
const createMarkup = (htmlContent) => {
  const sanitizedHTML = cleanHTML(htmlContent);
  return { __html: sanitizedHTML };
};

// Composant React pour afficher le contenu HTML
const DestinationComponent = ({ destinationData }) => {
  return (
    <div>
      {/* Utiliser dangerouslySetInnerHTML pour afficher le contenu HTML nettoyé */}
      <div dangerouslySetInnerHTML={createMarkup(destinationData.content_intro)} />
      <div className="hidden-mobile">
          <div id="text-img">
            <div id="text">
              <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p1)} />
            </div>
            <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-1.webp`}  alt={`Visitez ${destinationData.name}`} />
          </div>
          <div id="text-img">
            <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-2.webp`}  alt={`Visitez ${destinationData.name}`} />
            <div id="text">
              <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p2)} />
            </div>
          </div>
          {destinationData.content_p3 && (
            <div id="text-img">
              <div id="text">
                <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p3)} />
              </div>
              <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-3.webp`}  alt={`Visitez ${destinationData.name}`} />
            </div>
          )}
          {destinationData.content_p4 && (
            <div id="text-img">
              <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-4.webp`}  alt={`Visitez ${destinationData.name}`} />
              <div id="text">
                <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p4)} />
              </div>
            </div>
          )}
        </div>


        <div className="hidden-desktop">
        <div id="text-img">
          <div id="text">
            <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p1)} />
          </div>
          <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-1.webp`}  alt={`Visitez ${destinationData.name}`} />
        </div>
        <div id="text-img">
          <div id="text">
            <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p2)} />
          </div>
          <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-2.webp`}  alt={`Visitez ${destinationData.name}`} />
        </div>
        {destinationData.content_p3 && (
            <div id="text-img">
              <div id="text">
                <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p3)} />
              </div>
              <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-3.webp`}  alt={`Visitez ${destinationData.name}`} />
            </div>
          )}
          {destinationData.content_p4 && (
            <div id="text-img">
              <div id="text">
                <div dangerouslySetInnerHTML={createMarkup(destinationData.content_p4)} />
              </div>
              <img src={`${FILES_URL}/img/destinations/${destinationData.slug}-4.webp`}  alt={`Visitez ${destinationData.name}`} />
            </div>
          )}
        </div>
    </div>
  );
};

export default DestinationComponent;