import React, { useState } from 'react';
import './App.css'; // Assurez-vous d'ajouter les styles appropriés
import { supabase } from './supabaseClient'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom'; // Importez useNavigate pour la redirection
const BurgerMenu = ({ currentUser, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault(); // Empêche le comportement par défaut du lien
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Gestion après la déconnexion réussie, par exemple, rediriger vers la page d'accueil
      navigate('/');
      toggleMenu();
    } else {
      // Gérer l'erreur de déconnexion si nécessaire
      console.error('Erreur de déconnexion:', error.message);
    }
  };


  return (
    <div>
      <button className="burger-icon" onClick={toggleMenu}>
        ☰
      </button>
      {isOpen && (
        <div className="menu-container">
           <button className="burger-icon" onClick={toggleMenu}><i className="fa-solid fa-xmark"></i></button>
          {currentUser ? (
            <>
              <a href="/voyages">Mes voyages</a>
              <a href="/mon-compte">Mon compte</a>
              <a href="/" onClick={handleLogout}>Déconnexion</a>
            </>
          ) : (
            <>
              <a href="/inscription">S'inscrire</a>
              <a href="/connexion">Se connecter</a>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
