import React, { useContext, useEffect, useState, useRef } from 'react';
import { supabase } from './supabaseClient'; 
import SearchContext from './SearchContext'; 
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/'
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com'

function CountryPage() {
    const { searchParams } = useContext(SearchContext);
    const { id } = useParams();
    const [countryData, setCountryData] = useState(null);
    const [countryDestinationsData, setCountryDestinationsData] = useState(null);
    const [countryId, setCountryId] = useState(null);

    const [user, setUser] = useState(null); 
    const ref = useRef(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
          setUser(session?.user || null); // Mettez à jour l'état de l'utilisateur avec l'utilisateur connecté ou null
        });
      }, []);
      
      useEffect(() => {
        const countryName = id;
      
        fetch(`${API_URL}api/countries/${countryName}`)
          .then(response => response.json())
          .then(data => {
            const foundCountryId = data[0]?.id;
            if (foundCountryId) {
              setCountryId(foundCountryId);
            } else {
            }
          })
          .catch(error => {
            console.error('Erreur lors de la récupération de l\'ID du pays:', error);
          });
      }, [id]);


      useEffect(() => {
        if (countryId) {
          fetch(`${API_URL}api/country/${countryId}`) 
            .then(response => response.json())
            .then(data => {
              setCountryData(data);
            })
            .catch(error => {
              console.error('Error fetching country data:', error);
            });
        } else {
        }
      }, [countryId]);

      useEffect(() => {
        if (countryId) {
          fetch(`${API_URL}api/country/destinations/${countryId}`) 
            .then(response => response.json())
            .then(data => {
              setCountryDestinationsData(data);
            })
            .catch(error => {
              console.error('Error fetching destinations:', error);
            });
        } else {
        }
      }, [countryId]);




  if (!countryData) {
    return <div>Loading...</div>;
  }
  const canonicalUrl = `https://www.vaiago.com/${countryData.slug}`;


  return (
    <div className="App">    
      <Helmet>
        <title>Découvrez {countryData.name} lors de votre prochain voyage</title>
        <meta name="description" content={`Découvrez toutes les destinations à découvrir en ${countryData.name} - ${countryData.short_description}`} />
      </Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
      {countryData && <link rel="canonical" href={canonicalUrl} />}
      
      <div className="hero">
        <div className="hero-content">
           <h1>Que visiter en {countryData.name} ?</h1>
            <p>{countryData.short_description}</p>  

      </div>
        </div>
        <div className="container">
              <div className="favorites">
                {countryDestinationsData && countryDestinationsData.length > 0 ? countryDestinationsData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((destination) => (
                    <Link to={`/destination/${destination.slug}`} className="destination-link">
                        <div className="card-favorite">
                            <div className="picture">
                              <img src={`${FILES_URL}/img/destinations/${destination.slug}-cover.webp`}  alt={`Visitez ${destination.name}`} />
                            </div>
                            <div className="card-content">
                                <h3>{destination.name}</h3> 
                                <p>{destination.short_description}</p>
                            </div>
                        </div>
                    </Link>
                )) : <p>Nous n'avons pas encore sélectionné de destinations pour ce pays.</p>}
            </div>
           
        </div>

      
    </div>
  );
}

export default CountryPage;