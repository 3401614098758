import React, {useEffect} from 'react';

const Footer = () => {
  useEffect(() => {
    // Créer une fonction pour charger le script Axeptio
    const loadAxeptioScript = () => {
      window.axeptioSettings = {
        clientId: "65f5a722d0cbb9ad18bf7b00",
        cookiesVersion: "vaiago-fr-EU",
      };

      const script = document.createElement("script");
      script.async = true;
      script.src = "//static.axept.io/sdk.js";
      document.body.appendChild(script);
    };

    // Appeler la fonction pour charger le script
    loadAxeptioScript();

    // Optionnel : retourner une fonction de nettoyage si nécessaire
    return () => {
      // Ici, vous pouvez implémenter une logique de nettoyage si nécessaire
    };
  }, []); // Le tableau vide assure que l'effet s'exécute une seule fois après le premier rendu

  return (
    <footer>
        <div className="footer-link">
        <p><a href="/mentions-legales">Mentions légales</a></p>
        <p><a href="/conditions-generales-utilisation">CGU</a></p>
            <p>Copyright @Vaiago 2023 - 2024</p>
        </div>
        <div className="social_media">
          <a href="https://www.facebook.com/profile.php?id=100091504224439"><i className="fa-brands fa-facebook"></i></a>
          <a href="https://www.instagram.com/hello_vaiago/"><i className="fa-brands fa-instagram"></i></a>
        </div>
      </footer>
      
  );
}

export default Footer;