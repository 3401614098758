import React, { useState } from 'react';
import { supabase } from './supabaseClient'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    // Récupérer le token de réinitialisation du mot de passe depuis l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('token');

    if (!accessToken) {
      setError('Token de réinitialisation manquant.');
      setLoading(false);
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: newPassword
    })

    if (error) {
      setError(error.message);
    } else {
      setMessage('Votre mot de passe a été réinitialisé avec succès.');
      setTimeout(() => navigate('/connexion'), 3000); // Rediriger vers la page de connexion après 3 secondes
    }

    setLoading(false);
  };

  return (
    <div className="App">  
    <Helmet>
        <title>Vaiago - Connexion</title>
        <meta name="description" content="En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)" />
    </Helmet>
    <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
    <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
    <link rel="canonical" href="https://www.vaiago.com" />
      <h1>Réinitialiser le mot de passe</h1>
      <div className="container">
        <form onSubmit={handleResetPassword} className="form">
        <div className="input">
            <label className="label" htmlFor="password">Nouveau mot de passe :</label>
            <input type="password" placeholder="Mot de passe" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/> 
        </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Réinitialisation...' : 'Enregistrer'}
          </button>
          {error && <p className="error">{error}</p>}
        {message && <p className="message">{message}</p>}

        </form>
        </div>
    </div>
  );
};

export default ResetPassword;
