import React, { useContext, useEffect, useState } from 'react';
import Header from './Header'; // Adjust the path if necessary
import Footer from './Footer'; // Adjust the path if necessary


function Cookies() {
  return (
    <div className="terms">    
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com/mentions-legales"/>

        <div className="container">
            <div className="block-page">
                <h1>Politique relative aux cookies</h1>
                <p>Pour le bon fonctionnement du site et de ses services, nous utilisons des cookies lors de votre connexion. Un cookie est composé uniquement de texte et est stocké dans votre navigateur.</p>
                <p>Certains cookies sont obligatoires au bon fonctionnement du site. Ci-dessous, vous trouverez les différents cookies que nous utilisons et dans quel but.</p>
                <h2>Authentification</h2>
                <p>Ces cookies nous indiquent quand vous êtes connecté et vous permettent de vous reconnecter automatiquement à votre compte sans entrer de nouveau vos informations d&#39;identification.</p>
                <h2>Navigation</h2>
                <p>Ces cookies nous permettent d&#39;optimiser votre navigation sur le site en évitant des requêtes non nécessaires, cela vous offre une expérience plus fluide sur Vaiago.</p>
                <h2>Axeptio</h2>
                <p>Permet de recueillir le consentement des utilisateurs sur l&#39;utilisation des cookies.</p>
                <h2>Heap</h2>
                <p>Permet d&#39;analyser les statistiques de consultation de notre site.</p>
            </div>
        </div>
        
    </div>

  );
}

export default Cookies;
