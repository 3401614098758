import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SearchProvider } from './SearchContext';
import { supabase } from './supabaseClient';

// Import components //
import Header from './Header';
import Footer from './Footer';

// Import pages //
import HomePage from './HomePage';
import Terms from './Terms';
import SignUp from './SignUp';
import Account from './Account';
import GeneralConditions from './GeneralConditions';
import Login from './Login';
import Travels from './Travels';
import TravelPage from './TravelPage';
import ResetPassword from './ResetPassword';
import DestinationPage from './DestinationPage';
import CountryPage from './CountryPage';
import Countries from './Countries';
import Confidentiality from './Confidentiality';
import Cookies from './Cookies';

function App() {
  const [user, setUser] = useState(null);
  const [heapInitialized, setHeapInitialized] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        if (error.message === 'invalid claim: missing sub claim') {
          console.warn('User is not authenticated');
        } else {
          console.error('Error fetching authenticated user:', error.message);
        }
      } else {
        setUser(data.user);
      }
    };

    fetchUser();

    const loadHeapAnalytics = () => {
      try {
        const heapId = process.env.NODE_ENV === 'production' ? '3312625286' : '965605502';
        window.heap = window.heap || [];
        window.heap.load = function (e, t) {
          window.heap.appid = e;
          window.heap.config = t = t || {};
          const r = document.createElement("script");
          r.type = "text/javascript";
          r.async = true;
          r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
          r.onload = () => {
            if (user && !heapInitialized) {
              loadHeapUserData();
            }
            setHeapInitialized(true); // Mark heap as initialized
          };
          r.onerror = () => {
            console.error('Failed to load Heap script.');
          };
          const a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(r, a);
        };

        const p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"];
        const n = function (e) {
          return function () {
            if (typeof window.heap === 'undefined') {
              console.error('Heap is not defined.');
              return;
            }
            window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };

        window.heap.load(heapId);

      } catch (err) {
        console.error('Error in loadHeapAnalytics:', err.message);
      }
    };

    const loadHeapUserData = async () => {
      try {
        if (!user) return; // Ensure user is available
        const { data: userDetails, error: userError } = await supabase
          .from('user_details')
          .select('*')
          .eq('user_id', user.id);

        if (userError) {
          console.error('Error fetching user data:', userError.message);
          return;
        }

        if (!userDetails || userDetails.length === 0) {
          console.error('No user details found for user ID:', user.id);
          return;
        }

        const userDetail = userDetails[0]; // Select the first user detail

        // Identify the user and set user properties
        window.heap.identify(userDetail.user_id);
        window.heap.addUserProperties({
          email: userDetail.email,
          name: userDetail.name,
          signupDate: user.created_at
        });

      } catch (err) {
        console.error('Error in loadHeapUserData:', err.message);
      }
    };

    if (!heapInitialized) {
      loadHeapAnalytics(); // Always load Heap analytics
    }

    const checkAxeptioSDK = () => {
      if (window.axeptioSDK && typeof window.axeptioSDK.init === "function") {
        window.axeptioSDK.init({
          clientId: "65f5a722d0cbb9ad18bf7b00",
        });
        window.axeptioSDK.onUserChoice((consent) => {
          // Handle consent choices
        });
      } else {
        setTimeout(checkAxeptioSDK, 100);
      }
    };

    checkAxeptioSDK();

  }, [user, heapInitialized]);

  return (
    <SearchProvider>
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mentions-legales" element={<Terms />} />
          <Route path="/conditions-generales-utilisation" element={<GeneralConditions />} />
          <Route path="/politique-de-confidentialite" element={<Confidentiality />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/destination/:id" element={<DestinationPage />} />
          <Route path="/:id" element={<CountryPage />} />
          <Route path="/voyages/:id" element={<TravelPage />} />
          <Route path="/inscription" element={<SignUp />} />
          <Route path="/connexion" element={<Login />} />
          <Route path="/mon-compte" element={<Account />} />
          <Route path="/voyages" element={<Travels />} />
          <Route path="/pays" element={<Countries />} />
          <Route path="/reinitialisation" element={<ResetPassword />} />
        </Routes>
        <Footer/>
    </SearchProvider>
  );
}

export default App;
